import {
  Button,
  ChevronAndTitle,
  Heading,
  PillsContainer,
  ProductSuggestions,
  SearchBar,
} from '@rabbit/elements/shared-components';
import SearchGallery from '../../components/molecules/SearchGallery/SearchGallery';
import SearchSlider from '../../components/molecules/SearchSlider/SearchSlider';
import ROUTE_NAME from '../../utils/url-constants';
import { ChangeEvent, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { SearchResults, SearchVendables } from '@rabbit/search/cherchons';
import { DTVendable } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

interface GroupedResults {
  [type: string]: DTVendable[];
}

interface VendableSearchContainerProps {
  // If this is defined, then the user is on the NLIC flow
  onChangeStep?: (step_in: number, data: any) => void;
}
export function VendableSearchContainer({
  onChangeStep,
}: VendableSearchContainerProps) {
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [results, setResults] = useState<GroupedResults>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const QUERY_PARAM = 'searchParams';
  const searchParams = new URLSearchParams(window.location.search);

  const updateQueryString = (arr: string[]) => {
    navigate({
      search: arr?.length
        ? createSearchParams({
            searchParams: arr.join(',').toLowerCase(),
          }).toString()
        : '',
    });
  };

  const addValue = (value: string) => {
    if (!searchValue.includes(value)) {
      const newArray = [...searchValue, value];
      if (newArray?.length) updateQueryString(newArray);
      if (noResults) updateQueryString([value]);
    }
  };

  const groupResultsByCategory = (
    data: SearchResults<DTVendable>,
    property: string
  ) => {
    const groupedData = data.items.reduce((groupedData, currentItem: any) => {
      const [propertyValues] = currentItem[property].slice(-1);

      if (propertyValues && !groupedData[propertyValues]) {
        groupedData[propertyValues] = [];
      }

      if (propertyValues && groupedData[propertyValues]) {
        groupedData[propertyValues].push(currentItem);
      }

      return groupedData;
    }, {} as Record<string, DTVendable[]>);

    return groupedData;
  };

  const findResults = async (searchQuery: string) => {
    let data = await SearchVendables(searchQuery, {
      hitsPerPage: 500,
      page: 0,
      facetFilters: [
        {
          name: 'mfr',
          value: `M:${t('tenantLink')}`,
        },
      ],
      //facetFilters: [
      //{ name: 'docid', value: 'sv1-c3bduni3whdq5ipy' },
      //{name: 'series', value: 'umbrella'},
      //],
    });
    // filter out unpublished items
    const publishedItems = data.items.filter(
      (item) => item.published !== false && !item.unique
    );
    data = { ...data, items: publishedItems };
    const groupedData = groupResultsByCategory(data, 'category');
    //console.log(`findResults`,{data,groupedData});

    if (Object.values(groupedData).length) {
      setResults(groupedData);
      setNoResults(false);
    } else {
      setResults({});
      setNoResults(true);
    }

    return groupedData;
  };

  const handleSubmitSearch = (event?: { key: string }) => {
    if (!searchInput) return;
    if (event && event.key !== 'Enter') return;

    addValue(searchInput);
    setSearchInput('');
  };

  const handleQuickSearch = (value: string) => {
    addValue(value);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    setSearchInput(event.target.value);
  };

  const handleShowMore = async (value: string) => {
    if (!searchValue.includes(value)) {
      setSearchValue([...searchValue, value]);
      addValue(value);
    }

    const filteredResults = Object.keys(results || {}).reduce((acc, key) => {
      const group = (results || {})[key];

      const matchingItems = group.filter(
        (item) => item.series === value && item.published !== false
      );

      if (matchingItems.length > 0) {
        acc[key] = matchingItems;
      }
      return acc;
    }, {} as GroupedResults);

    setResults(filteredResults);
  };

  const handleDeleteSearchItem = (index: number, value: string) => {
    const newSearchValue = [...searchValue];
    newSearchValue.splice(index, 1);
    setSearchValue(newSearchValue);
    if (newSearchValue) updateQueryString(newSearchValue);
  };

  //check if searchValue is updated and then call API if needed
  useEffect(() => {
    if (searchValue.length) {
      findResults(searchValue.join(','))
        .then((data) => console.log('success:', data))
        .catch((err) => console.log(err));
    } else setResults([] as unknown as GroupedResults);
  }, [searchValue]);

  //check if queryString was updated and then updated the search values for results
  useEffect(() => {
    const paramValues = searchParams.get(QUERY_PARAM) || '';
    if (paramValues.split(', ') !== searchValue) {
      const paramsArray = paramValues.split(',');
      if (paramsArray && paramsArray[0]?.length) setSearchValue(paramsArray);
      else setSearchValue([]);
    }
  }, [window.location.search, QUERY_PARAM]);

  return (
    <>
      <div className="mt-[42px] max-w-full">
        <SearchBar
          value={searchInput}
          handleChangeCallBack={handleChange}
          submitSearchCallback={handleSubmitSearch}
          placeholder={'Type something here'}
        />
      </div>
      {searchValue.length > 0 && (
        <div className="mt-4">
          <PillsContainer
            values={searchValue}
            onClick={handleDeleteSearchItem}
          />
        </div>
      )}
      <div>
        {noResults && searchValue.length ? (
          <div className="mt-[25px] mb-4">
            <p className="font-nunito font-bold">No products were found.</p>
          </div>
        ) : null}
        {(results && Object.keys(results).length < 1) ||
        !results ||
        noResults ? (
          <>
            <div className="mt-[25px]">
              <ProductSuggestions submitSearchCallback={handleQuickSearch} />
            </div>
            <div className="mt-8 flex flex-col gap-4">
              <Button
                kind="primary"
                children="Scan product barcode or QR code"
                onClick={() =>
                  onChangeStep
                    ? onChangeStep(99, null)
                    : navigate(ROUTE_NAME.PRODUCTS_SCAN)
                }
              />
              {!onChangeStep && (
                <>
                  <Heading kind="p" className="mx-auto">
                    or
                  </Heading>
                  <Button
                    kind="outline"
                    children="Add manually"
                    onClick={() => navigate(ROUTE_NAME.PRODUCTS_ADD)}
                  />
                </>
              )}
            </div>
          </>
        ) : null}

        {results
          ? Object.keys(results).map((key, index) => (
              <div key={index}>
                {Object.keys(results).length > 1 ? (
                  <div className="mt-8">
                    <SearchSlider
                      title={key.replace(/-/g, ' ')}
                      actionButtonText="Show All"
                      data={results[key]}
                      onClick={handleShowMore}
                    />
                  </div>
                ) : (
                  <div className="py-2 lg:pt-8">
                    <SearchGallery
                      onChangeStep={onChangeStep}
                      data={results[key]}
                    />
                  </div>
                )}
              </div>
            ))
          : null}
      </div>
    </>
  );
}
export default VendableSearchContainer;
