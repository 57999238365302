import classNames from 'classnames';

export interface SliderImageProps {
  imageUrl: string;
  size: 'sm' | 'md' | 'NLIC';
  subtitle: string;
  title: string;
  onClick: any; //todo
}

const dynamicStyles = {
  // base: 'bg-cover bg-gray-50 bg-no-repeat bg-center',
  base: 'bg-contain bg-no-repeat bg-center border border-gray-100 cursor-pointer bg-white', //TODO: check what should be the final position of imgs
  sm: 'rounded-xl w-[159px] lg:w-[225px] h-[159px]',
  md: 'rounded-lg w-full h-[171px]',
  NLIC: 'rounded-lg w-full h-[90px]',
  containerSM: 'lg:w-[225px] cursor-pointer',
  containerMD: 'w-full cursor-pointer',
};

export function SearchImage({
  imageUrl,
  size,
  subtitle,
  title,
  onClick,
}: SliderImageProps) {
  return (
    <div
      className={classNames({
        [`${dynamicStyles.containerMD}`]: size === 'md',
        [`${dynamicStyles.containerSM}`]: size === 'sm',
      })}
    >
      <div
        style={{ backgroundImage: 'url(' + imageUrl + ')' }}
        className={classNames(`${dynamicStyles.base}`, {
          [`${dynamicStyles.md}`]: size === 'md',
          [`${dynamicStyles.sm}`]: size === 'sm',
          [`${dynamicStyles.NLIC}`]: size === 'NLIC',
        })}
        onClick={onClick}
      ></div>
      <div>
        <p className="mt-2 text-sm">{subtitle}</p>
        <p className="w-full text-base font-medium text-black">{title}</p>
      </div>
    </div>
  );
}

export default SearchImage;
