import { useNavigate } from 'react-router-dom';
import {
  TabsGeneric,
  Heading,
  EmptyListCTA,
  CardRepair,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { useGetConsumerCases } from '@rabbit/bizproc/react';
import ROUTE_NAME from '../../utils/url-constants';
import noProductsImg from '../../assets/images/rat_anvil.png';
import { getStoneImages } from '@rabbit/sage/utils/helpers';

/* eslint-disable-next-line */
export interface RepairsViewProps {}

export function RepairsView(props: RepairsViewProps) {
  const navigate = useNavigate();
  const { openCaseIds, closedCaseIds } = useGetConsumerCases() || {};

  const getCases = () => {
    return openCaseIds?.map((singleCase) => {
      return (
        <CardRepair
          key={singleCase.case}
          id={singleCase}
          onClick={() => navigate(`${ROUTE_NAME.REPAIRS}/${singleCase.case}`)}
          getStoneImages={getStoneImages}
          showSpotlight
        />
      );
    });
  };

  const getClosedCases = () => {
    return closedCaseIds?.map((singleCase) => {
      return (
        <CardRepair
          key={singleCase.case}
          id={singleCase}
          getStoneImages={getStoneImages}
          onClick={() => navigate(`${ROUTE_NAME.REPAIRS}/${singleCase.case}`)}
        />
      );
    });
  };

  const tabs = [
    {
      label: 'Active',
      children: (
        <div>
          {openCaseIds === undefined ? (
            <LoadingSpinner size={'xs'} />
          ) : openCaseIds.length === 0 ? (
            <div className="flex w-full justify-center">
              <div className="sm:w-2/3 md:w-1/2 lg:w-1/3">
                <EmptyListCTA
                  copy={
                    'There are no active repairs yet! Initiate a claim and those will be listed here'
                  }
                  imgSrc={noProductsImg}
                  imgAlt={'sad face'}
                />
              </div>
            </div>
          ) : (
            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
              {getCases()}
            </div>
          )}
        </div>
      ),
    },
    {
      label: 'Completed',
      children: (
        <div>
          {closedCaseIds === undefined ? (
            <LoadingSpinner size={'xs'} />
          ) : closedCaseIds.length === 0 ? (
            <div>
              <EmptyListCTA
                copy={'There are no completed repairs yet!'}
                imgSrc={noProductsImg}
                imgAlt={'sad face'}
              />
            </div>
          ) : (
            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
              {getClosedCases()}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="mb-4">
        <Heading kind="h2">Your repairs</Heading>
      </div>
      <TabsGeneric items={tabs} />
    </div>
  );
}

export default RepairsView;
