import { useState } from 'react';
import { Heading } from '@rabbit/elements/shared-components';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { List, ListItem } from '@mui/material';

export interface ItemProps {
  id?: string;
  title: string;
  description?: string;
  included?: string;
}

export interface InfoProps {
  title: string;
  type: string;
  items?: ItemProps[];
  children?: any;
}

export interface WarrantyInfoProps {
  data: InfoProps;
}

export function WarrantyInfo(props: WarrantyInfoProps) {
  const { data } = props;
  const [selected, setSelected] = useState(null);

  const handleToggleDescription = (itemId: any) => {
    setSelected((prevSelected) => (prevSelected === itemId ? null : itemId));
  };

  return (
    <div className="mb-6 flex flex-col">
      <Heading kind={'h5'} className="text-[14px]">
        {data.title}
      </Heading>
      <div className="font-nunito border-primary-100 mt-4 flex flex-col overflow-hidden rounded-[6px] border bg-gray-50 text-gray-600">
        {data.items &&
          data.items.map((item, index) => (
            <div
              key={index}
              className="flex flex-col border-b border-gray-200 last:border-0"
            >
              <div
                className={`flex cursor-pointer items-center justify-between gap-2 ${
                  selected === index ? 'bg-white' : 'bg-gray-50'
                } px-4 py-4`}
                onClick={() => handleToggleDescription(index)}
              >
                <div className="flex flex-1 items-start gap-2">
                  {data.type === 'checks' && (
                    <div className="h-5 w-5 shrink-0">
                      {item.included === 'yes' ? (
                        <CheckCircleIcon className="fill-green-500 text-white" />
                      ) : (
                        <XCircleIcon className="fill-red-500 text-white" />
                      )}
                    </div>
                  )}
                  <div className="font-nunito text-base font-medium text-gray-900">
                    {item.title}
                  </div>
                </div>
                {item.description && (
                  <div className="h-4 w-4">
                    <ChevronUpIcon
                      className={`${
                        selected === index
                          ? 'text-gray-900'
                          : 'rotate-180 text-gray-400 '
                      }`}
                    />
                  </div>
                )}
              </div>
              <div
                className={`flex w-full flex-col bg-white px-4 text-base ${
                  data.type === 'checks' ? 'pl-11' : ''
                } ${selected === index ? '-mt-2 pb-4' : ''}`}
              >
                {selected === index && item.description && (
                  <div
                    className="font-nunito font-normal "
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        {data.children && typeof data.children === 'string' && (
          <div
            className="p-4 text-base leading-[22px]"
            dangerouslySetInnerHTML={{
              __html: data.children,
            }}
          ></div>
        )}
        {data.children && data.children instanceof Array && (
          <List>
            {data.children.map((item, index) => (
              <ListItem>
                <div
                  className="text-base leading-[22px]"
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                ></div>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </div>
  );
}

export default WarrantyInfo;
