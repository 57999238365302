import {
  Button,
  Heading,
  Input,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  DTVendable,
  FBD_Holding_Private,
  UserUploadedDocument,
} from '@rabbit/data/types';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  OliveHoldingRegistrationFormData,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import {
  FileStorageContext,
  getCompletedUploadsOfCategory,
  LIST_COUNTRIES,
  useSendEmail,
} from '@rabbit/bizproc/react';
import { UserContext } from '@rabbit/olive/context/UserContext';
import { OurDateTime } from '@rabbit/utils/ts';
import { t } from 'i18next';
import { ConfigContext } from '@rabbit/config/context';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import { useMyConsumerPersona } from '@rabbit/data/portal';

//@ts-ignore
const isDemoEnv = import.meta.env.VITE_DEMO;

interface FormValuesShape {
  purchase_time: Date | null;
  purchase_price: {
    amount: number;
    currency: string;
  };
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  serial_number: string;
  serial_proof: UserUploadedDocument[];
}

interface RegisterHoldingInformationStepProps {
  onChange: (value: number, holdingCreationResponse: string) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setProductRegistrationData: React.Dispatch<OliveHoldingRegistrationFormData>;
  product: DTVendable;
  createUserHolding: (
    holdingData: OliveHoldingRegistrationFormData
  ) => Promise<string | Error>;
}

export function RegisterHoldingInformationStep({
  onChange,
  setIsLoading,
  product,
  createUserHolding,
}: RegisterHoldingInformationStepProps) {
  const CFG_CLAIMS_FLOW = t('CFG_CLAIMS_FLOW');
  const { config } = useContext(ConfigContext);
  const serialNumberRequired =
    config.HOLDINGS.REGISTRATION.SERIAL_NUMBER_REQUIRED;
  const persona = useMyConsumerPersona();

  const validationSchema = Yup.object().shape({
    purchase_location: Yup.mixed(),
    store_not_listed: Yup.boolean(),
    purchase_price: Yup.object({
      amount: Yup.number()
        .min(1, 'Please enter a valid amount')
        .required('Please enter a valid amount'),
      currency: Yup.string().trim().required('Currency is required'),
    })
      .required('Please enter a purchase price')
      .typeError('Please enter a purchase price'),
    purchase_time: Yup.date()
      .max(new Date(), 'Please enter a valid date')
      .required('Please select a purchase time')
      .typeError('Please enter a valid date'),
    ...(isDemoEnv ||
      (serialNumberRequired && {
        serial_number: Yup.string()
          .trim()
          .required('Please enter a serial number'),
      })),
    serial_proof: serialNumberRequired
      ? Yup.array().min(1).required('Please upload a serial number proof')
      : Yup.array(),
  });

  const appInfo = useAppInfo();
  const [isSubmiting, setSubmiting] = useState(false);
  const { SE_Olive_Holding_Registered } = useSendEmail();
  const { consumerPersonaData } = useContext(UserContext) ?? {};

  const { uploadQueueState, moveCompletedUploadsToAttached } =
    useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState ?? {};
  const serialNumberProofUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.SerialNumberProof
    );
  const serialNumber = window.localStorage.getItem('LRP_VendableSn') ?? '';
  //const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);
  const initialValues: FormValuesShape = {
    purchase_time: null,
    purchase_price: {
      amount: 0,
      currency: appInfo.currency,
    },
    purchase_location: null,
    serial_number: serialNumber,
    store_not_listed: false,
    custom_store_name: '',
    purchase_country: '',
    serial_proof: [],
  };

  const holding_private = FBD_Holding_Private.empty();
  const holdingId = config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF
    ? FBD_Holding_Private.keygen(holding_private, FBD_Holding_Private)
    : '';

  const onSubmit = async (values: FormValuesShape) => {
    setSubmiting(true);
    setIsLoading(true);

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;

    const data = {
      purchase_location: newPurchaseLocation,
      purchase_location_other: newPurchaseLocationOther,
      purchase_price: {
        amount: Number(values.purchase_price.amount),
        currency: values.purchase_price.currency,
      },
      purchase_time: values.purchase_time
        ? OurDateTime.dateToTimestamp(values.purchase_time)
        : 0,
      serial: values?.serial_number ?? '',
      purchase_country: values.purchase_location
        ? values.purchase_location.country
        : values.purchase_country,
      serial_proof: values.serial_proof || [],
    };

    try {
      const res = await createUserHolding(data);
      const serialNumberProofFiles = serialNumberProofUploads?.flatMap((file) =>
        file.category === UploadedFileCategories.SerialNumberProof &&
        file?.uploadedFile
          ? [file.uploadedFile]
          : []
      );
      if (res) {
        if (
          moveCompletedUploadsToAttached &&
          serialNumberProofFiles &&
          serialNumberProofFiles.length > 0
        ) {
          moveCompletedUploadsToAttached(serialNumberProofUploads);
        }
      }
      if (window.localStorage.getItem('LRP_VendableSn')) {
        window.localStorage.removeItem('LRP_VendableSn');
      }
      toast.success(
        'Product registered successfully! Please upload your receipt.'
      );
      setIsLoading(false);
      if (typeof res === 'string') onChange(2, res);
    } catch (err) {
      toast.error('Something went wrong, please try again.');
      setSubmiting(false);
      setIsLoading(false);
      console.log(err);
    }
  };

  if (!CFG_CLAIMS_FLOW) return <LoadingSpinner size="sm" />;

  return (
    <div className="flex flex-col gap-4">
      <Heading kind={'h3'}>When and where did you buy your product?</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={onSubmit}
        //innerRef={formikRef}
      >
        {({ errors, values }) => (
          <Form className="font-nunito flex flex-col gap-4">
            <Input
              type="datepicker"
              name="purchase_time"
              settings={{
                placeholder: 'Purchase date',
                maxDate: new Date(),
                hint: '*required',
              }}
            />
            <Input
              type="currency"
              name="purchase_price"
              settings={{
                id: 'purchase_price',
                placeholder: 'Purchase amount',
                currency: values?.purchase_price?.currency ?? appInfo.currency,
                hint: '*required',
              }}
            />
            <Input
              type="autocomplete-location-retailer"
              name="purchase_location"
              settings={{
                placeholder: 'Enter store name & location',
                isMulti: false,
                disabled: values.store_not_listed,
                options: [],
              }}
            />
            <Input
              type="checkbox"
              name="store_not_listed"
              settings={{
                checkboxLabel: 'Store is not listed',
                checkboxLabelStyles: 'text-base text-gray-500',
              }}
            />
            {values.store_not_listed && (
              <>
                <Input
                  type="text"
                  name="custom_store_name"
                  settings={{
                    id: 'custom_store_name',
                    placeholder: 'Enter store name',
                  }}
                />
                <Input
                  type="select"
                  label=""
                  name="purchase_country"
                  settings={{
                    options: LIST_COUNTRIES,
                    id: 'purchase_country',
                    placeholder: 'The country where the product was purchased',
                  }}
                />
              </>
            )}
            <Input
              type="text"
              name="serial_number"
              settings={{
                placeholder: `Serial number ${serialNumberRequired ? '*' : ''}`,
                hint: serialNumberRequired ? '*required' : undefined,
                disabled: serialNumber !== '',
              }}
            />
            {config.HOLDINGS.REGISTRATION.SERIAL_NUMBER_REQUIRED && (
              <OliveFileUploader
                label={t('Photo of serial number') + '*'}
                identifiers={{
                  category: UploadedFileCategories.SerialNumberProof,
                  docType: {
                    docid: holdingId,
                    type: DocTypeShapeTypes.Holding,
                  },
                  personaId: persona?.personaId ?? '',
                }}
                name="serial_proof"
                shouldAutoUpdateDocs={false}
                currentFiles={values.serial_proof ?? []}
                maxFiles={1}
                accepts={['image/*', '.pdf']}
              />
            )}
            <Button
              kind="primary"
              type="submit"
              disabled={
                Object.keys(values).length === 0 ||
                Object.keys(errors).length > 0 ||
                (config.HOLDINGS.REGISTRATION.SERIAL_NUMBER_REQUIRED &&
                  values.serial_proof?.length === 0) ||
                // values?.purchase_time === 0 ||
                // values?.purchase_location?.length === 0 ||
                isSubmiting
              }
              loading={isSubmiting}
            >
              Confirm details
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RegisterHoldingInformationStep;
