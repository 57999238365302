import {
  Button,
  ChevronAndTitle,
  LoadingSpinner,
  WarrantyInfo,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ROUTE_NAME from '../../../utils/url-constants';
import { SearchVendablesByDocid } from '@rabbit/search/cherchons';
import { DTVendable } from '@rabbit/data/types';
import { getWarrantyInfo, useAppInfo } from '@rabbit/olive/utils/helpers';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

export interface VendableInfoContainerProps {
  docid: string;
  onChangeStep?: (step_in: number, data?: any) => void;
  holdingId?: string | null;
}

export interface ItemProps {
  id?: string;
  title: string;
  description?: string;
  included?: string;
}

export interface InfoInterface {
  title: string;
  type: string;
  items?: ItemProps[];
  children?: any;
}

export function VendableInfoContainer({
  docid,
  onChangeStep,
}: VendableInfoContainerProps) {
  const appInfo = useAppInfo();
  const [showWarrantyDetails, setShowWarrantyDetails] = useState(false);
  const [product, setProduct] = useState<DTVendable>();
  const [warrantyInfo, setWarrantyInfo] = useState<InfoInterface[]>([]);
  const [warrantyTerms, setWarrantyTerms] = useState<InfoInterface[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const params = useParams();
  const productPlaceHolder = t(
    'CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER',
    { defaultValue: '' }
  );

  useEffect(() => {
    getProductDetails(docid).catch((err) => console.log(err));
  }, [docid]);

  const getProductDetails = async (docid: string) => {
    const facetFilters = [];
    facetFilters.push({
      name: 'mfr',
      value: `M:${t('tenantLink')}`,
    });

    const options = {
      hitsPerPage: 25,
      page: 0,
      facetFilters,
    };
    const data = await SearchVendablesByDocid(docid, options);
    setProduct(data.items[0]);
  };

  useEffect(() => {
    if (window) window.localStorage.removeItem('LRP_VendableId');
    const getWarranty = async () => {
      const warranty = await getWarrantyInfo(t('tenantLink'));
      if (warranty) {
        setWarrantyInfo(warranty.warranty_info);
        setWarrantyTerms(warranty.warranty_terms);
      }
    };
    void getWarranty();
  }, [t('tenantLink')]);

  return !product ? (
    <LoadingSpinner size="sm" />
  ) : (
    <div className="mt-6">
      {!onChangeStep && (
        <ChevronAndTitle
          title={
            searchParams.get('holdingId')
              ? t('Complete registration')
              : t('Product information')
          }
          onClick={() => navigate(-1)}
        />
      )}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-12">
        <div
          className={`h-fit rounded-[6px] bg-white lg:w-[480px] ${
            onChangeStep
              ? 'w-full'
              : 'basis-1/2 md:shadow-[4px_6px_24px_rgba(0,0,0,0.08);]'
          }`}
        >
          <div>
            <div className="flex flex-col items-center justify-center pt-8">
              <div className="flex flex-col">
                <img
                  src={appInfo.logo}
                  alt={appInfo.name}
                  className="flex max-h-[64px] justify-start object-contain"
                />

                <div
                  className={`flex items-center ${
                    product ? 'h-[360px]' : 'h-[400px]'
                  } w-full`}
                >
                  <img
                    src={product?.img?.[0] ?? productPlaceHolder}
                    className="h-full w-full object-contain"
                  />
                </div>
              </div>
              <div className="flex w-full flex-col items-center gap-6 border-t border-gray-200 px-8 py-4 pb-8 md:bg-gray-100">
                <span className="font-nunito text-center text-2xl font-bold capitalize text-gray-500">
                  {product.title}
                </span>
                <div className="flex flex-col">
                  <Button
                    className="w-fit"
                    children={
                      <>
                        {params.holdingId
                          ? t('View product')
                          : searchParams.get('holdingId')
                          ? t('Complete registration')
                          : !onChangeStep
                          ? t(`Register product`)
                          : t('Initiate claim')}
                        <ArrowRightIcon className="ml-2 h-4 w-4" />
                      </>
                    }
                    kind={'primary'}
                    onClick={() => {
                      if (onChangeStep) {
                        window.localStorage.setItem(
                          'NLIC_VendableId',
                          product.docid
                        );
                        window.localStorage.setItem(
                          'NLIC_VendableName',
                          product.title
                        );
                        onChangeStep(4);
                      } else {
                        searchParams.get('holdingId')
                          ? navigate(
                              ROUTE_NAME.PRODUCTS_PREVIEW +
                                '/' +
                                product.docid +
                                '/register?holdingId=' +
                                searchParams.get('holdingId')
                            )
                          : params.holdingId
                          ? navigate(
                              ROUTE_NAME.PRODUCTS +
                                '/' +
                                params.holdingId +
                                '/details'
                            )
                          : navigate(
                              ROUTE_NAME.PRODUCTS_PREVIEW +
                                '/' +
                                product.docid +
                                '/register'
                            );
                      }
                    }}
                  />
                  {!onChangeStep && (
                    <div className="mt-5 flex md:hidden ">
                      <Button
                        children={'Warranty information'}
                        kind={'outline'}
                        onClick={() =>
                          setShowWarrantyDetails(!showWarrantyDetails)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!onChangeStep && (
          <div
            className={classNames(
              `hidden basis-1/2 rounded-[6px] md:!block md:max-h-[620px] md:bg-white md:shadow-[4px_6px_24px_rgba(0,0,0,0.08);]`,
              {
                [`!block`]: showWarrantyDetails === true,
              }
            )}
          >
            <div className="overflow-hidden">
              <div className="">
                <div className="hidden text-center md:block">
                  <div className="font-caudex h-[64px] border-b border-gray-200 py-4 text-xl font-bold md:text-2xl">
                    {warrantyInfo?.length > 0 && (
                      <>
                        {appInfo.name === 'NuCover'
                          ? 'Care Plan Summary'
                          : 'Warranty information'}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col overflow-y-auto py-6 px-0 md:max-h-[calc(620px-64px)] md:px-8">
                {warrantyInfo &&
                  warrantyInfo.map((data: InfoInterface, index: number) => {
                    return <WarrantyInfo key={index} data={data} />;
                  })}
                {warrantyTerms &&
                  warrantyTerms.map((data: InfoInterface, index: number) => {
                    return <WarrantyInfo key={index} data={data} />;
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default VendableInfoContainer;
