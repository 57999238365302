import { Heading } from '@rabbit/elements/shared-components';
import classNames from 'classnames';

export interface CardImageBgProps {
  dailyMsg: boolean;
  message?: string;
  text?: string;
  tag?: string;
  imageUrl: string;
  onClick?: () => void;
}

const dynamicStyles = {
  containerOuter:
    'w-full rounded-md font-nunito relative shadow-md overflow-hidden',
  containerInner:
    'w-full rounded-md font-nunito text-[#F3F3F3] absolute inset-0 z-[1]',
  containerOuterDailyMsg: 'h-[190px] cursor-default',
  containerOuterWithoutDaily: 'h-[160px] cursor-pointer',
  containerInnerDailyMsg: 'flex justify-center items-center p-4 text-center',
  containerInnerWithoutDaily: 'flex items-end px-5 p-[14px]',
};

export function CardImageBg({
  dailyMsg,
  message,
  text,
  tag,
  imageUrl = 'https://picsum.photos/1000/179',
  onClick,
}: CardImageBgProps) {
  return (
    <div
      className={classNames(`${dynamicStyles.containerOuter}`, {
        [`${dynamicStyles.containerOuterDailyMsg}`]: dailyMsg === true,
        [`${dynamicStyles.containerOuterWithoutDaily}`]: dailyMsg === false,
      })}
      onClick={onClick}
    >
      <div
        className={classNames(`${dynamicStyles.containerInner}`, {
          [`${dynamicStyles.containerInnerDailyMsg}`]: dailyMsg === true,
          [`${dynamicStyles.containerInnerWithoutDaily}`]: dailyMsg === false,
        })}
      >
        {dailyMsg ? (
          <Heading kind={'h3'} color={'text-white'}>
            "{message}"
          </Heading>
        ) : (
          <div className="max-w-[285px] items-end justify-end">
            <p className="mb-[10px] text-base font-bold">{text}</p>
            <p className="text-sm font-normal">{tag}</p>
          </div>
        )}
      </div>
      <span
        className="absolute inset-0 z-[0] h-full w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(' +
            imageUrl +
            ')',
        }}
      ></span>
    </div>
  );
}

export default CardImageBg;
