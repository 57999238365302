// This file is for actions that are not generic, for example:
// - Repair specific actions
// - Shelta specific actions

import {
  ClientCaseFlowCase,
  ConsumerHoldingSummaryShape,
} from '@rabbit/bizproc/client';
import { DTHolding_Public, DTVendable, FBD_Vendable } from '@rabbit/data/types';
import { getUnixTime } from 'date-fns';
import {
  Caseflow_CaseCreation_Olive_FormDataShape,
  Caseflow_CaseCreation_Sage_Shelta_FormDataShape,
  IsValidLinkToDocument,
} from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';

export async function Consumer_NewCase_AddInitialFacts(
  cfc: ClientCaseFlowCase,
  holdingDataSummary: ConsumerHoldingSummaryShape,
  initialCaseData: Caseflow_CaseCreation_Olive_FormDataShape,
  consumer_name: string,
  consumer_email: string,
  consumer_holding: string,
  consumer_persona_id: string
) {
  cfc.Alter_Facts({
    consumer_name,
    consumer_email,
    consumer_persona_id,
    holding_vendable_id: holdingDataSummary.vendableId,
    consumer_holding: consumer_holding,
    consumer_holding_name: holdingDataSummary.title,
    consumer_holding_image: holdingDataSummary.img,
    consumer_holding_serial_number: holdingDataSummary.serial,
    consumer_holding_purchase_country:
      holdingDataSummary.purchase_country ?? '-',
    consumer_issue_type: initialCaseData.consumer_issue_type,
    consumer_issue_type_ref: initialCaseData.consumer_issue_type_ref,
    consumer_issue_description:
      initialCaseData.consumer_issue_description ?? '',
    consumer_proof_of_purchase:
      initialCaseData.consumer_proof_of_purchase ?? [],
    consumer_claim_evidence: initialCaseData.consumer_claim_evidence ?? [],
    consumer_holding_purchase_location:
      holdingDataSummary.full_purchase_location?.docid ?? '',
    consumer_holding_purchase_location_other:
      holdingDataSummary.purchase_location_other ?? '',
    consumer_holding_purchase_price: holdingDataSummary.purchase_price ?? '-',
    case_last_updated: Math.floor(Date.now() / 1000),
    case_time_created: OurDateTime.nowUTCTimestamp(),
    purchase_date: holdingDataSummary.purchase_time,
    holding_warranty_term: holdingDataSummary.warranty_term,
    delegate_repairer_id: 'Unassigned',
    delegate_repairer_name: 'Unassigned',
    delegate_installer_id: 'Unassigned',
    delegate_installer_name: 'Unassigned',
    consumer_holding_category: holdingDataSummary.category,
  });
}

export async function Repairer_NewCase_AddInitialFacts(
  cfc: ClientCaseFlowCase,
  initialCaseData: Caseflow_CaseCreation_Sage_Shelta_FormDataShape,
  holding: DTHolding_Public,
  consumer_name: string,
  consumer_email: string,
  holdingCategory: string
) {
  let vendable: DTVendable | null = null;

  if (holding.vendable && IsValidLinkToDocument(holding.vendable)) {
    vendable = await FBD_Vendable.get(holding.vendable);
  }

  if (!vendable) throw new Error('Vendable not found');

  const { title, img } = vendable;

  cfc.Alter_Facts({
    consumer_name: consumer_name,
    consumer_persona_id: holding.consumer,
    consumer_email: consumer_email,
    consumer_holding: holding.docid,
    consumer_holding_name: title,
    consumer_holding_image: img?.[0] ?? '',
    consumer_issue_type: initialCaseData.consumer_issue_type,
    consumer_issue_type_ref: initialCaseData.consumer_issue_type_ref,
    consumer_issue_description:
      initialCaseData.consumer_issue_description ?? '',
    consumer_proof_of_purchase:
      initialCaseData.consumer_proof_of_purchase ?? [],
    consumer_claim_evidence: initialCaseData.consumer_claim_evidence ?? [],
    consumer_holding_purchase_location:
      initialCaseData.consumer_holding_purchase_location ?? '',
    consumer_holding_purchase_location_other:
      initialCaseData.consumer_holding_purchase_location_other ?? '',
    case_last_updated: Math.floor(Date.now() / 1000),
    consumer_preferred_contact: initialCaseData.consumer_preferred_contact,
    internal_comment: initialCaseData.internal_comment,
    case_time_created: OurDateTime.nowUTCTimestamp(),
    purchase_date: holding.purchase_time,
    holding_warranty_term: {
      amount: 12,
      division: 'months',
    },
    consumer_holding_category: holdingCategory,
    delegate_repairer_id: 'Unassigned',
    delegate_repairer_name: 'Unassigned',
    delegate_installer_id: 'Unassigned',
    delegate_installer_name: 'Unassigned',
    holding_vendable_id: holding.vendable,
  });
}
