/* eslint-disable @typescript-eslint/no-unused-vars */

// Routing
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoutes from '../utils/PrivateRoutes';
import PublicRoutes from '../utils/PublicRoutes';
import ROUTE_NAME from '../utils/url-constants';
// React Query
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
// Pages
import AuthView from '../views/AuthView';
import DashboardView from '../views/DashboardView';
import VendableSearchView from '../views/vendables/search/VendableSearch';
import VendablePreviewView from '../views/vendables/preview/VendablePreviewView';
import CompleteProfileView from '../views/CompleteProfileView';
import ImpactView from '../views/impact/ImpactView';
import AccountView from '../views/account/AccountView';
import RepairsView from '../views/repairs/RepairsView';
import MarketingPreferencesView from '../views/account/AccountPrivacyView/marketing-preferences/MarketingPreferencesView';
import RepairsSingleView from '../views/repairs/RepairsSingleView';
import VendableScanView from '../views/vendables/scan/VendableScanView';
import HoldingsView from '../views/holdings/HoldingsView';
import HoldingRegisterView from '../views/holdings/register/HoldingRegisterView';
import HoldingSingleDetailsView from '../views/holdings/single/HoldingSingleDetailsView';
import HoldingSingleClaimView from '../views/holdings/claim-creation/HoldingSingleClaimView';
import HoldingSingleEditView from '../views/holdings/edit/HoldingSingleEditView';
import HoldingSingleWarrantyInfoView from '../views/holdings/warranty-info/HoldingSingleWarrantyInfoView';
import HoldingSingleAddEditDocs from '../views/holdings/edit/HoldingSingleAddEditDocs';
import PersonalInfoView from '../views/account/PersonalInfoView/PersonalInfoView';
import AccountPrivacyView from '../views/account/AccountPrivacyView/AccountPrivacyView';
import AddressesView from '../views/account/AddressesView/AddressesView';
import PrivacyPolicyView from '../views/privacy-policy/PrivacyPolicyView';
import TermsAndConditionsView from '../views/terms-and-conditions/TermsAndConditionsView';
import NonLoggedInClaimView from '../views/non-logged-in-claim/NonLoggedInClaimView';
import AdditionalInfoView from '../views/account/AdditionalInfoView/AdditionalInfoView';
import { NotFoundView } from '@rabbit/elements/shared-components';
import LandingRegisterProductView from '../views/LandingRegisterProductView';
import OnboardingView from '../views/OnboardingView';
import AddSRVHoldingView from '../views/holdings/self-registered-vendables/AddSRVHoldingView';
import SRVHoldingEditView from '../views/holdings/self-registered-vendables/SRVHoldingEditView';
import ArticleView from '../views/ArticleView';
import ServiceRequestView from '../views/ServiceRequestView';
import ServiceRequestRouter from '../views/service-request/ServiceRequestRouter';
// Misc
import 'flowbite';
import '@rabbit/utils/buildinfo'; // Lets us see information about builds from within them
import { UserProviderWrapper } from '../context/UserContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import { articles } from '../utils/articles';
import { ScrollToTop } from '@rabbit/bizproc/react';
import HoldingSingleQuestionnaireView from '../views/holdings/questionnaire/HoldingSingleQuestionnaire';
import 'react-loading-skeleton/dist/skeleton.css';
import { MixMasterWrapper } from '@rabbit/mixmaster/react';
import { GenericLexicon } from '@rabbit/mixmaster/core';
import { base } from '@rabbit/mixmaster/veils';
import AuthErrorView from '../views/AuthErrorView';
import { ConfigProviderWrapper } from '@rabbit/config/context';
import HoldingPreview from '../views/holdings/register/HoldingPreview';
import RegisterView from '../views/register-product/RegisterView';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <div
      id="app"
      className="bg-canvas flex min-h-[-webkit-fill-available] min-h-screen w-full justify-center"
    >
      <QueryClientProvider client={queryClient}>
        <MixMasterWrapper bootstrap={base.core as GenericLexicon}>
          <BrowserRouter>
            <ScrollToTop />
            <ConfigProviderWrapper>
              <UserProviderWrapper>
                <ReactQueryDevtools initialIsOpen={false} />
                <Routes>
                  <Route element={<PrivateRoutes />}>
                    {/* USER IS LOGGED */}
                    <Route
                      path={ROUTE_NAME.DASHBOARD}
                      element={<DashboardView />}
                    />
                    <Route
                      path={ROUTE_NAME.ACCOUNT}
                      element={<AccountView />}
                    />
                    <Route
                      path={ROUTE_NAME.ACCOUNT_EDIT}
                      element={<PersonalInfoView />}
                    />
                    <Route
                      path={ROUTE_NAME.ACCOUNT_ADDITIONAL_INFO}
                      element={<AdditionalInfoView />}
                    />
                    <Route
                      path={ROUTE_NAME.ACCOUNT_ADDRESSES}
                      element={<AddressesView />}
                    />
                    <Route
                      path={ROUTE_NAME.ACCOUNT_PRIVACY}
                      element={<AccountPrivacyView />}
                    />
                    <Route
                      path={ROUTE_NAME.ACCOUNT_MARKETING}
                      element={<MarketingPreferencesView />}
                    />
                    <Route
                      path={ROUTE_NAME.COMPLETE_PROFILE}
                      element={<CompleteProfileView />}
                    />
                    <Route path={ROUTE_NAME.IMPACT} element={<ImpactView />} />
                    <Route
                      path={ROUTE_NAME.PRODUCTS}
                      element={<HoldingsView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/details'}
                      element={<HoldingSingleDetailsView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/claim'}
                      element={<HoldingSingleClaimView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/edit-info'}
                      element={<HoldingSingleEditView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/edit-srv'}
                      element={<SRVHoldingEditView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/documents'}
                      element={<HoldingSingleAddEditDocs />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/warranty-info'}
                      element={<HoldingSingleWarrantyInfoView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS + '/:id/questionnaire'}
                      element={<HoldingSingleQuestionnaireView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS_PREVIEW + '/:id/register'}
                      element={<HoldingRegisterView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS_PREVIEW + '/:id'}
                      element={<VendablePreviewView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS_SEARCH}
                      element={<VendableSearchView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS_ADD}
                      element={<AddSRVHoldingView />}
                    />
                    <Route
                      path={ROUTE_NAME.PRODUCTS_SCAN}
                      element={<VendableScanView />}
                    />
                    <Route
                      path={ROUTE_NAME.REPAIRS}
                      element={<RepairsView />}
                    />
                    <Route
                      path={ROUTE_NAME.REPAIRS + '/:id'}
                      element={<RepairsSingleView />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_COVER_OUTDOOR_FURNITURE}
                      element={<ArticleView data={articles[0]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_BEST_MATERIALS_OUTDOOR}
                      element={<ArticleView data={articles[1]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_REVAMP_OUTDOOR_SPACE}
                      element={<ArticleView data={articles[2]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_REVAMP_OUTDOOR_PATIO}
                      element={<ArticleView data={articles[3]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_PROTECT_FROM_SUN}
                      element={<ArticleView data={articles[4]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_PROTECT_FROM_RAIN}
                      element={<ArticleView data={articles[5]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_FURNITURE_LONGEVITY}
                      element={<ArticleView data={articles[6]} />}
                    />
                    <Route
                      path={ROUTE_NAME.ARTICLE_ANALYSE_GARDEN_SURFACE}
                      element={<ArticleView data={articles[7]} />}
                    />
                    <Route
                      path={ROUTE_NAME.SERVICE_REQUEST + '/start'}
                      element={<ServiceRequestView />}
                    />
                    {/* TODO */}
                  </Route>
                  <Route element={<PublicRoutes />}>
                    {/* USER IS A GUEST BUT WE SHOULD REDIRECT TO DASHBOARD IF LOGGED */}

                    <Route path={ROUTE_NAME.AUTH} element={<AuthView />} />
                    <Route
                      path={ROUTE_NAME.AUTH_ERROR}
                      element={<AuthErrorView />}
                    />
                  </Route>
                  <Route element={<ServiceRequestRouter />}>
                    <Route
                      path={ROUTE_NAME.SERVICE_REQUEST}
                      element={<ServiceRequestView />}
                    />
                  </Route>
                  <Route path="*" element={<NotFoundView />} />
                  <Route
                    path={ROUTE_NAME.ONBOARDING}
                    element={<OnboardingView />}
                  />
                  <Route
                    path={ROUTE_NAME.NON_LOGGED_START_CLAIM}
                    element={<NonLoggedInClaimView />}
                  />

                  <Route
                    path={ROUTE_NAME.NON_LOGGED_CONTINUE_CLAIM}
                    element={<NonLoggedInClaimView step={6} />}
                  />
                  <Route
                    path={ROUTE_NAME.PRIVACY_POLICY}
                    element={<PrivacyPolicyView />}
                  />
                  <Route
                    path={ROUTE_NAME.TERMS_AND_CONDITIONS}
                    element={<TermsAndConditionsView />}
                  />
                  <Route
                    path={ROUTE_NAME.LANDING_REGISTER_PRODUCT + '/:id'}
                    element={<LandingRegisterProductView />}
                  />
                  <Route
                    path={ROUTE_NAME.PRODUCTS_PREVIEW + 's/:id'}
                    element={<VendablePreviewView />}
                  />
                  <Route
                    path={
                      ROUTE_NAME.LANDING_NEW_PRODUCT + '/:vendableId/:holdingId'
                    }
                    element={<HoldingPreview />}
                  />
                  <Route
                    path={ROUTE_NAME.WELCOME}
                    element={<LandingRegisterProductView />}
                  />
                  <Route
                    path={ROUTE_NAME.REGISTER_PRODUCT_INSTALLER}
                    element={<RegisterView />}
                  />
                </Routes>
                <ToastContainer />
              </UserProviderWrapper>
            </ConfigProviderWrapper>
          </BrowserRouter>
        </MixMasterWrapper>
      </QueryClientProvider>
    </div>
  );
};
